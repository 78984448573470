import { useQuery } from '@tanstack/react-query'
import { Col, Row } from 'antd'
import queryString from 'query-string'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { ReactComponent as ImportUrugSvg } from "../../assets/icons/import-urug.svg"
import { ReactComponent as MahhalliyUrugSvg } from "../../assets/icons/mahaliy-urug.svg"
import { ReactComponent as YetishtirishTavsiyalarSvg } from "../../assets/icons/yetishtirish-tavsiyalar.svg"
import http from '../../utils/helpers'

const Seeds = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const { data: splingData } = useQuery({
        queryKey: ['get-urug-tavsiya-file'],
        queryFn: async () => {
            return await http.get('common/useful_files/urug_tavsiya/')
        }
    })

    const items = useMemo(() => [
        {
            type: "local_seed",
            title: t('local_seed'),
            icon: MahhalliyUrugSvg,
            color: "green",
            link: ''
        },
        {
            type: "import_seeds",
            title: t('import_seed'),
            icon: ImportUrugSvg,
            color: "green",
            link: ''
        },
        {
            type: "download",
            title: t('get_spling_file'),
            icon: YetishtirishTavsiyalarSvg,
            color: "green",
            link: splingData?.file
        },
    ], [t, splingData])

    return (
        <div className='p-5' style={{ minHeight: 'calc(100vh - 250px)' }}>
            <div className='mb-5 flex align-center'>
                <div className='arrow-icon' onClick={() => navigate(-1)}></div>
                <h2 className='m-0'>{t('seeds')}</h2>
            </div>

            <div className='text-right mb-5 flex justify-between items-center'>
                <span>{t('total')} {items?.length} {t('ta')}</span>
            </div>

            <Row gutter={[24, 24]}>
                {
                    items?.map((item, index) => {
                        return (
                            <Col xl={4} lg={6} md={8} sm={8} xs={12} key={index}>
                                {
                                    item?.type === "download" ? (
                                        <Link to={item?.link?.startsWith("https:") ? item?.link : `${item?.link?.replace(/^http:/, "https:")}`} target='_blank' rel='noreferrer' download={true}>
                                            <div className='custom-card p-4 min-h-36 flex justify-center items-center flex-col'>
                                                {item?.icon && <item.icon className='xl:w-16 xl:h-16 w-12 h-12 mb-2' style={{ color: item?.color }} />}
                                                <h4 className='m-0 text-black text-center'>{item?.title}</h4>
                                            </div>
                                        </Link>
                                    ) : (
                                        <div
                                            className='cursor-pointer'
                                            onClick={() => navigate(`${item?.type}/?${queryString.stringify({ title: item?.title })}`)}
                                        >
                                            <div className='custom-card p-4 min-h-36 flex justify-center items-center flex-col'>
                                                {item?.icon && <item.icon className='xl:w-16 xl:h-16 w-12 h-12 mb-2' style={{ color: item?.color }} />}
                                                <h4 className='m-0 text-black text-center'>{item?.title}</h4>
                                            </div>
                                        </div>
                                    )
                                }
                            </Col>
                        )
                    })
                }
            </Row>
        </div>
    )
}

export default Seeds