import { Col, Row } from 'antd'
import queryString from 'query-string'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { ReactComponent as ExportMinimalNarxSvg } from "../../assets/icons/export-minimal-narx.svg"
import { ReactComponent as MahalliyMahsulotlarUrtachaNarxSvg } from "../../assets/icons/mahalliy-mahsulotlar-urtacha-narx.svg"
import { ReactComponent as XorijdagiBozorMahsulotlariSvg } from "../../assets/icons/xorijdagi-bozor-mahsulotlari.svg"

const Prices = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const items = [
        {
            id: null,
            title: t('local_prices'),
            icon: ExportMinimalNarxSvg,
            color: 'blue',
            url: 'https://akk.karantin.uz/api/v2/common/url/export_price/'
        },
        {
            id: null,
            title: t('abroad_prices'),
            icon: XorijdagiBozorMahsulotlariSvg,
            color: 'blue',
            link: 'abroad-prices',
        },
        {
            id: null,
            title: t('local-prices'),
            icon: MahalliyMahsulotlarUrtachaNarxSvg,
            color: 'blue',
            link: 'local-prices',
        },
    ]

    return (
        <div className='p-5' style={{ minHeight: 'calc(100vh - 250px)' }}>
            <div className='mb-5 flex align-center'>
                <div className='arrow-icon' onClick={() => navigate('/')}></div>
                <h2 className='m-0'>{t('fruits')}</h2>
            </div>

            <div className='text-right mb-5 flex justify-between items-center'>
                <span>{t('total')} {items?.length} {t('ta')}</span>
            </div>

            <Row gutter={[24, 24]}>
                {
                    items?.map((item, index) => {
                        return (
                            <Col xl={4} lg={6} md={8} sm={8} xs={12} key={index}>
                                {
                                    item?.url ? (
                                        <Link to={item?.url} target='_blank' rel='noreferrer'>
                                            <div className='custom-card p-4 min-h-36 justify-center flex items-center flex-col'>
                                                {item?.icon && <item.icon className='xl:w-16 xl:h-16 w-12 h-12 mb-2' style={{ color: item?.color }} />}
                                                <h4 className='m-0 text-black text-center'>{item?.title}</h4>
                                            </div>
                                        </Link>
                                    ) : (
                                        <div
                                            className='cursor-pointer'
                                            onClick={() => navigate(`/${item?.link}/?${queryString.stringify({ title: item?.title })}`)}
                                        >
                                            <div className='custom-card p-4 min-h-36 justify-center flex items-center flex-col'>
                                                {item?.icon && <item.icon className='xl:w-16 xl:h-16 w-12 h-12 mb-2' style={{ color: item?.color }} />}
                                                <h4 className='m-0 text-black text-center'>{item?.title}</h4>
                                            </div>
                                        </div>
                                    )
                                }
                            </Col>
                        )
                    })
                }
            </Row>
        </div>
    )
}

export default Prices