import React, { useEffect, useRef } from 'react'
import Protection from './protection'
import ServicesTypes from './services-types'
import UsefulInformation from './useful-information'
import Eksport from './eksport'
import Import from './import'
import HomeNews from '../news'
import OtherServices from './other-services'
import { useLocation } from 'react-router-dom'
import LocalProductPrices from './local-product-prices'

const Home = () => {
    const sectionRefs = {
        "#plant-protection": useRef(null),
        "#services-types": useRef(null),
        "#useful-information": useRef(null),
        "#export": useRef(null),
        "#import": useRef(null),
        "#other-services": useRef(null),
    };

    const location = useLocation();

    const scrollToSectionWithOffset = (hash) => {
        const sectionRef = sectionRefs[hash];
        if (sectionRef && sectionRef.current) {
            const elementPosition = sectionRef.current.getBoundingClientRect().top + window.scrollY;
            const offsetPosition = elementPosition + 300; // Add 100px offset

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth",
            });
        }
    };

    useEffect(() => {
        if (location.hash) {
            scrollToSectionWithOffset(location.hash);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.hash]);

    return (
        <div className='p-5'>
            <HomeNews />
            <section id='plant-protection' ref={sectionRefs["#plant-protection"]}>
                <Protection />
            </section>
            <section id='services-types' ref={sectionRefs["#services-types"]}>
                <ServicesTypes />
            </section>
            <section id='useful-information' ref={sectionRefs["#useful-information"]}>
                <UsefulInformation />
            </section>
            <section id='export' ref={sectionRefs["#export"]}>
                <Eksport />
            </section>
            <section id='import' ref={sectionRefs["#import"]}>
                <Import />
            </section>
            <section id='other-services' ref={sectionRefs["#other-services"]}>
                <OtherServices />
            </section>
            <section id='local-product-prices' ref={sectionRefs["#local-product-prices"]}>
                <LocalProductPrices />
            </section>
        </div>
    )
}

export default Home