import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { ToastContainer } from 'react-toastify'
import { Provider } from 'react-redux'
import store from './store'
import { ConfigProvider, Spin } from 'antd'
import './index.css'
import 'antd/dist/reset.css'
import 'react-toastify/dist/ReactToastify.css'
import ScrollToTop from './ScrollToTop'
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'react-photo-view/dist/react-photo-view.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import "aos/dist/aos.css"
import ErrorBoundary from './components/error-boundary'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ['uz', 'uzb', 'ru', 'en'],
    fallbackLng: "uz",
    detection: {
      order: ['cookie', 'localStorage', 'path', 'subdomain'],
      caches: ['cookie']
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
    },
  });

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <Suspense fallback={<Spin />}>
      <ErrorBoundary>
        <BrowserRouter>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: '#147461',
              },
            }}
          >
            <QueryClientProvider client={queryClient}>
              <ScrollToTop />
              <App />
            </QueryClientProvider>
          </ConfigProvider>
        </BrowserRouter>
      </ErrorBoundary>
    </Suspense>
  </Provider>
)
