import { Col, Row } from 'antd'
import queryString from 'query-string'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { ReactComponent as RuyxatgaOlishMezonlariSvg } from "../../assets/icons/ruyxatga-olish-mezonlari.svg"
import { ReactComponent as TaqiqlanganModdalarSvg } from "../../assets/icons/taqiqlangan-moddalar.svg"

const TaqiqlanganModdalar = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const items = [
        {
            title: t('restricted_substances'),
            icon: TaqiqlanganModdalarSvg,
            color: 'red',
            link: 'https://karantin.uz/uploads/menu/taqiqlangan_kimyoviy_modalar.pdf',
            type: "download"
        },
        {
            title: t('registration_criteria'),
            link: 'mezonlar',
            icon: RuyxatgaOlishMezonlariSvg,
            color: 'green',
            type: "link"
        },
    ]

    return (
        <div className='p-5' style={{ minHeight: 'calc(100vh - 250px)' }}>
            <div className='mb-5 flex align-center'>
                <div className='arrow-icon' onClick={() => navigate(-1)}></div>
                <h2 className='m-0'>{t('criteria_type')}</h2>
            </div>

            <div className='text-right mb-5 flex justify-between items-center'>
                <span>{t('total')} {items?.length} {t('ta')}</span>
            </div>

            <Row gutter={[24, 24]}>
                {
                    items?.map((item, index) => {
                        return (
                            <Col xl={4} lg={6} md={8} sm={8} xs={12} key={index}>
                                {
                                    item?.type === "download" ? (
                                        <Link to={item?.link?.startsWith("https:") ? item?.link : `${item?.link?.replace(/^http:/, "https:")}`} target='_blank' rel='noreferrer' download={true}>
                                            <div className='custom-card p-4 min-h-36 justify-center flex items-center flex-col'>
                                                {item?.icon && <item.icon className='xl:w-16 xl:h-16 w-12 h-12 mb-2' style={{ color: item?.color }} />}
                                                <h4 className='m-0 text-black text-center'>{item?.title}</h4>
                                            </div>
                                        </Link>
                                    ) : (
                                        <div
                                            className='cursor-pointer'
                                            onClick={() => navigate(`${item?.link}/?${queryString.stringify({ title: item?.title })}`)}
                                        >
                                            <div className='custom-card p-4 min-h-36 justify-center flex items-center flex-col'>
                                                {item?.icon && <item.icon className='xl:w-16 xl:h-16 w-12 h-12 mb-2' style={{ color: item?.color }} />}
                                                <h4 className='m-0 text-black text-center'>{item?.title}</h4>
                                            </div>
                                        </div>
                                    )
                                }
                            </Col>
                        )
                    })
                }
            </Row>
        </div >
    )
}

export default TaqiqlanganModdalar