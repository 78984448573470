import { useQuery } from '@tanstack/react-query'
import { Col, Empty, Input, Row } from 'antd'
import queryString from 'query-string'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import http from '../../utils/helpers'
import Error from '../error'
import Loader from '../loader'

const ZoneCountries = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { zone } = useParams()

    const [search, setSearch] = useState("")

    const filter = useMemo(() => {
        return {
            search,
            zone_type: zone
        }
    }, [search, zone])

    const { data, isLoading, isError } = useQuery({
        queryKey: ['import-zone-countries', filter],
        queryFn: async () => {
            return await http.get(`import/zone-countries/`, {
                params: filter
            })
        }
    })

    return (
        <div className='p-5' style={{ minHeight: 'calc(100vh - 250px)' }}>
            <div className='mb-5 flex align-center'>
                <div className='arrow-icon' onClick={() => navigate(-1)}></div>
                <h2 className='m-0'>{t('phytosanitary_clean_areas')}</h2>
            </div>

            <div className='text-right mb-5 flex justify-between items-center'>
                <span>{t('total')} {data?.length} {t('ta')}</span>
                <Input
                    placeholder={t('search')}
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    className='w-[250px] text-left'
                />
            </div>

            {
                isLoading ? <Loader /> : (
                    <>
                        <Row gutter={[24, 24]}>
                            {
                                data?.map((item, index) => {
                                    return (
                                        <Col xl={4} lg={6} md={8} sm={12} xs={12} key={index}>
                                            <div
                                                className='custom-card p-4 py-8 min-h-36 justify-center flex items-center flex-col cursor-pointer'
                                                onClick={() => {
                                                    navigate(`country/${item?.id}?${queryString.stringify({ title: item?.title, zone })}`)
                                                }}
                                            >
                                                <img src={item?.country?.flag} alt={item?.title} className='xl:w-16 w-12 mb-2' />
                                                <h3 className='m-0 text-black text-center mt-auto'>{item?.title}</h3>
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>

                        {
                            data?.length === 0 ? <Empty /> : null
                        }
                    </>
                )
            }
            {isError ? <Error /> : null}
        </div>
    )
}

export default ZoneCountries