import { Divider, Row } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as BiolaboratoriyalarRuyxatiSvg } from "../../assets/icons/biolaboratoriyalar-ruyxat.svg"
import { ReactComponent as DalaChetiSvg } from "../../assets/icons/dala-cheti.svg"
import { ReactComponent as FeromonTutqichSvg } from "../../assets/icons/feromon-tutqich.svg"
import { ReactComponent as KorxonalarRuyxatiSvg } from "../../assets/icons/korxonalar-ruyxati.svg"
import { ReactComponent as MineralUgitOmborSvg } from "../../assets/icons/mineral-ugit-ombori.svg"
import { ReactComponent as OziqOvqatLaboratoriyalariSvg } from "../../assets/icons/oziq-ovqat-laboratoriyalari.svg"
import { ReactComponent as SertifikatlashSvg } from "../../assets/icons/sertifikatlash.svg"
import { ReactComponent as TuproqTahlilLaboratoriyalariSvg } from "../../assets/icons/tuproq-tahlil-laboratoriyalari.svg"
import { ReactComponent as UsimlikKlinikalariSvg } from "../../assets/icons/usimlik-klinikalari.svg"
import Card from '../../components/card'

const ServicesTypes = () => {
    const { t } = useTranslation()

    const xizmatTurlari = [
        {
            id: null,
            title: t('list_of_biolaboratories'),
            icon: BiolaboratoriyalarRuyxatiSvg,
            color: 'green',
            link: 'biolabs'
        },
        {
            id: null,
            title: t('exporter_companies'),
            icon: KorxonalarRuyxatiSvg,
            color: 'green',
            link: 'iso'
        },
        {
            id: null,
            title: t('certification'),
            icon: SertifikatlashSvg,
            color: 'green',
            link: 'certification'
        },
        {
            id: null,
            title: t('herbal_clinics'),
            icon: UsimlikKlinikalariSvg,
            color: 'green',
            link: 'clinics'
        },
        {
            id: null,
            title: t('ses_laboratory'),
            icon: OziqOvqatLaboratoriyalariSvg,
            color: 'green',
            link: 'ses-laboratory'
        },
        {
            id: null,
            title: t('warehouse'),
            icon: MineralUgitOmborSvg,
            color: 'green',
            link: 'mineral-ogit-ombor'
        },
        {
            id: null,
            title: t('soil_analysis_laboratories'),
            icon: TuproqTahlilLaboratoriyalariSvg,
            color: 'green',
            link: 'tuproq-tahlil-lab'
        },
        {
            id: null,
            title: t('feromon'),
            icon: FeromonTutqichSvg,
            color: 'green',
            link: 'feromon-tutqich'
        },
        {
            id: null,
            title: t('field_age'),
            icon: DalaChetiSvg,
            color: 'green',
            link: 'field-edge',
        },
    ]

    return (
        <>
            <h1 className='mb-5'>{t('services_types')}</h1>
            <Row
                gutter={[24, 24]}
            >
                {
                    xizmatTurlari?.map((item, index) => {
                        return <Card key={index} item={item} />
                    })
                }
            </Row>
            <br />
            <Divider />
        </>
    )
}

export default ServicesTypes