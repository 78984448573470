import { useQuery } from '@tanstack/react-query'
import { Divider, Select, Table } from 'antd'
import dayjs from 'dayjs'
import queryString from 'query-string'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { makeOptions, reFormat } from '../../utils'
import http from '../../utils/helpers'
import Error from '../error'
import TimeseriesChart from './chart'

const InnerLocalPrices = () => {
    const navigate = useNavigate()
    const params = useParams()

    const { title } = queryString.parse(window.location.search)

    const productCodes = params?.productCodes?.split(',')

    const { t } = useTranslation()

    const [regionId, setRegionId] = useState(null)
    const [districtId, setDistrictId] = useState(null)
    const [defaultMonth, setDefaultMonth] = useState(6)

    const defaultDates = useMemo(() => [
        dayjs().subtract(6, 'month'), dayjs()
    ], [])

    const [dateStrings, setDateStrings] = useState(defaultDates.map((date) => date.format('YYYY-MM-DD')))

    const filter = useMemo(() => {
        return {
            products_codes: productCodes,
        }
    }, [productCodes])

    const { data, isLoading, isError } = useQuery({
        queryKey: ['export-local-price-data', filter],
        queryFn: async () => {
            return await http.get(`export/local/price/data/`, {
                params: filter
            })
        },
    })

    const { data: regions, isLoading: regionsLoading } = useQuery({
        queryKey: ['export-local-price-regions'],
        queryFn: async () => {
            return await http.get(`export/local/price/regions/`)
        }
    })

    const { data: districts, isLoading: districtsLoading } = useQuery({
        queryKey: ['export-local-price-districts', regionId],
        queryFn: async () => {
            return await http.get(`export/local/price/districts/`, {
                params: {
                    region_id: regionId
                }
            })
        },
        enabled: !!regionId
    })

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'region',
            render: (text, obj, index) => {
                return (
                    <span> {index + 1} </span>
                )
            },
            width: '60px',
            align: 'center',
        },
        {
            title: t("region"),
            dataIndex: ['region', 'name'],
            key: 'region',
            width: '200px',
            align: 'center',
        },
        {
            title: t('last-price'),
            dataIndex: 'price',
            key: 'price',
            width: '200px',
            align: 'center',
            render: (data) => <span>{reFormat(data)} {t('sum')}</span>
        },
        {
            title: t("date-inter"),
            dataIndex: 'date_inter',
            key: 'date_inter',
            width: '200px',
            align: 'center',
            render: (date) => dayjs(date).format("YYYY-MM-DD")
        },
    ];

    useEffect(() => {
        setDateStrings([
            dayjs().subtract(defaultMonth, 'month').format('YYYY-MM-DD'),
            dayjs().format('YYYY-MM-DD')
        ])
    }, [defaultMonth])

    useEffect(() => {
        if (regions && regions?.length > 0) {
            setRegionId(regions[0].id)
        }
    }, [regions])

    useEffect(() => {
        if (districts && districts?.length > 0) {
            setDistrictId(districts[0].id)
        }
    }, [districts])

    if (isError) {
        return <Error />
    }

    return (
        <div className='p-5' style={{ minHeight: 'calc(100vh - 250px)' }}>
            <div className='mb-5 flex align-center'>
                <div className='arrow-icon' onClick={() => navigate(-1)}></div>
                <h2 className='m-0'>{title}</h2>
            </div>

            <div className='flex justify-between items-center mb-2 xl:flex-row lg:flex-row md:flex-row flex-col'>
                {/* <span className='mb-2'>{t('total')} {data?.length} {t('ta')}</span> */}
                <span></span>
                <div className='filter-wrap mb-2'>
                    <Select
                        placeholder={t('category')}
                        loading={regionsLoading}
                        value={regionId}
                        options={makeOptions(regions, 'name')}
                        onChange={(e) => {
                            setRegionId(e)
                            setDistrictId(null)
                        }}
                        allowClear={false}
                        showSearch
                        filterOption={(input, option) =>
                            (option?.label || '').toLowerCase().includes(input.toLowerCase())
                        }
                    />

                    <Select
                        placeholder={t('category')}
                        loading={districtsLoading}
                        value={districtId}
                        options={makeOptions(districts, 'name')}
                        allowClear={false}
                        onChange={(e) => setDistrictId(e)}
                        showSearch
                        filterOption={(input, option) =>
                            (option?.label || '').toLowerCase().includes(input.toLowerCase())
                        }
                    />
                </div>
            </div>

            <TimeseriesChart
                dateStrings={dateStrings}
                regionId={regionId}
                districtId={districtId}
                setDefaultMonth={setDefaultMonth}
                defaultMonth={defaultMonth}
                // productId={productId}
                productCodes={productCodes}
            />

            <Divider className='my-10' />

            <h2>{t('final-avg-price')}</h2>
            <Table
                columns={columns}
                dataSource={data}
                loading={isLoading}
                rowKey={"id"}
                pagination={{ pageSize: 20 }}
                // scroll={{
                //     x: 'scroll',
                //     y: "60vh"
                // }}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => {
                            setRegionId(record?.region?.id)
                        }
                    }
                }
                }
                rowClassName={(record, index) => {
                    return record?.region?.id === regionId ? 'active-table-row cursor-pointer' : 'cursor-pointer'
                }
                }
            />
        </div>
    )
}

export default InnerLocalPrices