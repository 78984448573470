import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { Col, Empty, Pagination, Row, Select } from 'antd'
import queryString from 'query-string'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { makeOptions } from '../../utils'
import http from '../../utils/helpers'
import Error from '../error'
import Loader from '../loader'

const FirstInnerPage = () => {
    const { t } = useTranslation()
    const params = useParams()
    const navigate = useNavigate()
    const { title } = queryString.parse(window.location.search)

    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(24)
    const [categoryId, setCategoryId] = useState(null)

    const filter = useMemo(() => {
        return {
            agro_category__category: params.id,
            page: categoryId ? 1 : currentPage,
            page_size: pageSize,
            agro_category: categoryId
        }
    }, [params, currentPage, pageSize, categoryId])

    const { data, isLoading, isError } = useQuery({
        queryKey: ['agro-products', filter],
        queryFn: async () => {
            return await http.get(`agro/products/`, {
                params: filter
            })
        },
        placeholderData: keepPreviousData
    })

    const { data: categories, isLoading: categoriesLoading } = useQuery({
        queryKey: ['categories', { category: params.id, page_size: 100 }],
        queryFn: async () => {
            return await http.get(`/agro/`, {
                params: {
                    category: params.id,
                    page_size: 100
                }
            })
        }
    })

    return (
        <div className='p-5' style={{ minHeight: 'calc(100vh - 250px)' }}>
            <div className='mb-5 flex align-center'>
                <div className='arrow-icon' onClick={() => navigate(-1)}></div>
                <h2 className='m-0'>{title}</h2>
            </div>

            <div className='text-right mb-5 flex justify-between items-center'>
                <span>{t('total')} {data?.count} {t('ta')}</span>
                <Select
                    className='w-[250px] text-left'
                    placeholder={t('categories')}
                    loading={categoriesLoading}
                    value={categoryId}
                    options={makeOptions(categories?.results, 'title')}
                    onChange={(e) => setCategoryId(e)}
                    allowClear
                />
            </div>

            {
                isLoading ? <Loader /> : (
                    <>
                        <Row gutter={[24, 24]}>
                            {
                                data?.results?.map((item, index) => {
                                    return (
                                        <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                                            <div
                                                className='custom-card cursor-pointer p-4 flex items-center flex-col'
                                                onClick={() => {
                                                    navigate(`/product/details/${item?.id}?${queryString.stringify({ title: item?.title })}`)
                                                }}
                                            >
                                                <img src={item?.image} alt={item?.title} className='xl:w-16 xl:h-16 w-12 h-12 mb-2' />
                                                <h4 className='m-0 text-black text-center'>{item?.title}</h4>
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>

                        {
                            data?.results?.length === 0 ? <Empty /> : null
                        }

                        {
                            Array.isArray(data?.results) ? (
                                <div className='text-right mt-5'>
                                    <Pagination
                                        defaultCurrent={currentPage}
                                        current={currentPage}
                                        pageSize={pageSize}
                                        showSizeChanger={true}
                                        pageSizeOptions={[24, 36, 60]}
                                        onChange={(current, size) => {
                                            setCurrentPage(current)
                                            setPageSize(size)
                                        }}
                                        total={data?.count}
                                    />
                                </div>
                            ) : null
                        }
                    </>
                )
            }
            {isError ? <Error /> : null}
        </div>
    )
}

export default FirstInnerPage