import { Divider, Row } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as InspektorniChaqirishSvg } from "../../assets/icons/inspektorni-chaqirish.svg"
import { ReactComponent as SertifikatlashniTekshirishSvg } from "../../assets/icons/sertifikatlashni-tekshirish.svg"
import { ReactComponent as NormativHuquqiyHujjatlarSvg } from "../../assets/icons/normativ-huquqiy-hujjatlar.svg"
import Card from '../../components/card'
import MobileAppInfoModal from '../../modals/mobile-app-info-modal'

const OtherServices = () => {
    const { t } = useTranslation()

    const [isOpenModal, setIsOpenModal] = useState(false)

    const eksport = [
        {
            id: null,
            title: t('call_the_inspector'),
            icon: InspektorniChaqirishSvg,
            color: 'brown',
            link: 'call-inspektor',
            type: 'download'
        },
        {
            id: null,
            title: t('check_certificate'),
            icon: SertifikatlashniTekshirishSvg,
            color: 'brown',
            link: 'check-sertificate'
        },
        {
            id: null,
            title: t('normative_legal_documents'),
            icon: NormativHuquqiyHujjatlarSvg,
            color: 'brown',
            link: 'documents'
        },
    ]

    const getData = () => {
        setIsOpenModal(true)
    }

    return (
        <>
            {
                isOpenModal ? (
                    <MobileAppInfoModal
                        isOpenModal={isOpenModal}
                        setIsOpenModal={setIsOpenModal}
                    />
                ) : null
            }

            <h1 className='mb-5'>{t('other_service')}</h1>
            <Row
                gutter={[24, 24]}
            >
                {
                    eksport?.map((item, index) => {
                        return <Card key={index} item={item} url={item?.url} getData={getData} />
                    })
                }
            </Row>
            <br />
            <Divider />
        </>
    )
}

export default OtherServices