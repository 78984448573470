import queryString from 'query-string'
import React, { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import http from '../../utils/helpers'
import Loader from '../loader'
import Error from '../error'
import { Col, Empty, Row, Segmented } from 'antd'
import parse from 'html-react-parser'
import { useTranslation } from 'react-i18next'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

const InnerExportProduct = () => {
    const params = useParams()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { title } = queryString.parse(window.location.search)

    const filter = useMemo(() => {
        return {
            export_products: params.productId,
            export_country: params.countryId
        }
    }, [params])

    const { data, isLoading, isError } = useQuery({
        queryKey: ['export-country-product', filter],
        queryFn: async () => {
            return await http.get(`export/v2/country/list/`, {
                params: filter
            })
        },
        placeholderData: keepPreviousData,
    })

    const [activeTab, setActiveTab] = useState('requirement')

    const options = useMemo(() => {
        const baseOptions = [{ label: t('requirement'), value: 'requirement' }];
        if (data?.[0]?.exporter_companies_exsists) {
            baseOptions.push({ label: t('exporter_companies'), value: 'exporter_companies' });
        }
        if (data?.[0]?.exporter_gardens_exsists) {
            baseOptions.push({ label: t('exporter_gardens'), value: 'exporter_gardens' });
        }
        return baseOptions;
    }, [data, t]);

    if (isLoading) {
        return <Loader />
    }
    if (isError) {
        return <Error />
    }

    return (
        <div className='p-5' style={{ minHeight: 'calc(100vh - 250px)' }}>
            <div className='mb-10 flex align-center'>
                <div className='arrow-icon' onClick={() => navigate(-1)}></div>
                <h2 className='m-0'>{title}</h2>
            </div>

            <h3 className='mb-5'>{data?.title}</h3>
            <div style={{ overflowX: 'auto' }}>
                <Segmented
                    style={{
                        marginBottom: 16,
                    }}
                    options={options}
                    value={activeTab}
                    onChange={e => setActiveTab(e)}
                />
            </div>
            {
                activeTab === 'requirement' ? (
                    <div>
                        {parse(data[0]?.requirement)}
                    </div>
                ) : null
            }

            {
                activeTab === 'exporter_companies' ? (
                    <>
                        <p className='mb-4'>{t('total')} {data[0]?.exporter_companies?.length} {t('ta')}</p>
                        <Row gutter={[24, 24]}>
                            <>
                                {
                                    data[0]?.exporter_companies?.map((item, index) => {
                                        return (
                                            <Col xl={6} lg={8} md={12} sm={24} xs={24} key={index}>
                                                <div className='custom-card p-4 h-[100%] flex flex-col'>
                                                    <p className='text-[#147461]'>{t('company_name')} <b>{item?.company_name}</b></p>
                                                    <p className='text-[#147461]'>{t('address')} <b>{item?.address}</b></p>
                                                    <p className='text-[#147461]'>{t('registered_number_in_uz')} <b>{item?.registered_number}</b></p>
                                                    <p className='text-[#147461]'>{t('email_or_website')} <b>{item?.email_or_website}</b></p>
                                                    <p className='text-[#147461]'>{t('company_owner')} <b>{item?.company_owner}</b></p>
                                                    <p className='text-[#147461] m-0'>{t('company_phone')} <b><a href={`tel:${item?.phone_number}`}>{item?.phone_number}</a></b></p>
                                                </div>
                                            </Col>
                                        )
                                    })
                                }
                                {
                                    data[0].exporter_companies_exsists === false ? <Empty /> : null
                                }
                            </>
                        </Row>
                    </>
                ) : null
            }

            {
                activeTab === 'exporter_gardens' ? (
                    <>
                        <p className='mb-4'>{t('total')} {data[0]?.exporter_gardens?.length} {t('ta')}</p>
                        <Row gutter={[24, 24]}>
                            <>
                                {
                                    data[0]?.exporter_gardens?.map((item, index) => {
                                        return (
                                            <Col xl={6} lg={8} md={12} sm={24} xs={24} key={index}>
                                                <div className='bg-[#00000005] h-[100%]'>
                                                    <div className='custom-card p-4 h-[100%] flex flex-col'>
                                                        <p className='text-[#147461]'>{t('company_name')} <b>{item?.garden_name}</b></p>
                                                        <p className='text-[#147461]'>{t('address')} <b>{item?.garden_address}</b></p>
                                                        <p className='text-[#147461]'>{t('garden_number')} <b>{item?.garden_number}</b></p>
                                                        <p className='text-[#147461] m-0'>{t('area_of_the_garden')} <b>{item?.garden_field} {t('a_hectare')}</b></p>
                                                    </div>
                                                </div>
                                            </Col>
                                        )
                                    })
                                }
                                {
                                    data[0].exporter_gardens_exsists === false ? <Empty /> : null
                                }
                            </>

                        </Row>
                    </>
                ) : null
            }
        </div>
    )
}

export default InnerExportProduct