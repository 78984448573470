import { useQuery } from '@tanstack/react-query'
import { Col, Row } from 'antd'
import Cookies from 'js-cookie'
import queryString from 'query-string'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import http from '../../utils/helpers'
import Error from '../error'
import Loader from '../loader'

const Library = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const initialLang = Cookies.get('i18next')

    const { data, isLoading, isError } = useQuery({
        queryKey: ['common-library-category'],
        queryFn: async () => {
            return await http.get(`common/library/category/`, {
            })
        },
    })

    if (isLoading) {
        return <Loader />
    } else if (isError) {
        return <Error />
    } else
        return (
            <div className='p-5' style={{ minHeight: 'calc(100vh - 250px)' }}>
                <div className='mb-5 flex align-center'>
                    <div className='arrow-icon' onClick={() => navigate(-1)}></div>
                    <h2 className='m-0'>{t('electronic_library')}</h2>
                </div>

                <div className='text-right mb-5 flex justify-between items-center'>
                    <span>{t('total')} {data?.length} {t('ta')}</span>
                </div>

                <Row gutter={[24, 24]}>
                    {
                        data?.map((item, index) => {
                            return (
                                <Col xl={4} lg={6} md={8} sm={8} xs={12} key={index}>
                                    <div
                                        className='cursor-pointer'
                                        onClick={() => {
                                            navigate(`books/?${queryString.stringify({ id: item?.id })}`)
                                        }}
                                    >
                                        <div className='custom-card p-4 h-[100%] flex items-center flex-col'>
                                            <img src={item?.logo} alt='' className='xl:w-16 xl:h-16 w-12 h-12 mb-2' />
                                            <h4 className='m-0 text-black text-center'>{item[`name_${initialLang}`] ? item[`name_${initialLang}`] : item?.name_uz}</h4>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>
        )
}

export default Library