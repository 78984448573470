// import { Button } from '@/components/ui/button';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { Button } from 'antd';
import { ErrorBoundary as ClassBoundary } from 'react-error-boundary';
import codeErrorImg from '../../assets/images/code-error.png';

const ErrorBoundary = ({ children }) => {
    return (
        <QueryErrorResetBoundary>
            {({ reset }) => (
                <ClassBoundary
                    onReset={reset}
                    fallbackRender={({ resetErrorBoundary }) => (
                        <div className="container mx-auto flex h-screen items-center justify-center flex-col space-y-3 p-2 text-center">
                            <img
                                src={codeErrorImg}
                                alt="error"
                                className="w-36"
                            />
                            <h3 className="text-4xl font-semibold text-primary">
                                Qandaydir xatolik bo'ldi
                            </h3>
                            <p className="text-sm font-semibold">
                                Xavotir olmang, Jamoamiz xatolik ustida ishlayapti!
                            </p>
                            <a href='/'>
                                <Button type='primary' onClick={resetErrorBoundary}>Bosh sahifaga qaytish</Button>
                            </a>
                        </div>
                    )}
                >
                    {children}
                </ClassBoundary>
            )}
        </QueryErrorResetBoundary>
    );
};

export default ErrorBoundary;
