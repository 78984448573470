import { useQuery } from '@tanstack/react-query'
import { Col, Divider, Empty, Row, Spin } from 'antd'
import queryString from 'query-string'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Keyboard, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { reFormat, setUTCTime } from '../../utils'
import http from '../../utils/helpers'

// Import Swiper styles
import 'swiper/css'

const LocalProductPrices = () => {

    const { t } = useTranslation()

    const navigate = useNavigate()

    const { data, isLoading } = useQuery({
        queryKey: ['export-local-price-product'],
        queryFn: async () => {
            return await http.get(`export/local/price/product/group/`, {
                params: {
                    page: 1,
                    page_size: 12,
                },
            })
        }
    })

    return (
        <div className='p-5'>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <h1 className='mb-5'>{t('local-prices')}</h1>
                <span className='cursor-pointer text-blue-600' onClick={() => navigate("/local-prices/")}>{t("all")}</span>
            </div>

            <div className='flex justify-center items-center'>
                {isLoading ? <Spin /> : null}
            </div>

            <Row
                gutter={[24, 24]}
            >
                <Swiper
                    breakpoints={
                        {
                            576: {
                                slidesPerView: 2,
                            },
                            768: {
                                slidesPerView: 2,
                            },
                            992: {
                                slidesPerView: 3,
                            },
                            1200: {
                                slidesPerView: 4,
                            },
                            1400: {
                                slidesPerView: 5,
                            },
                            1600: {
                                slidesPerView: 6,
                            },
                        }}
                    spaceBetween={20}
                    keyboard={{
                        enabled: true,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Keyboard, Pagination, Navigation]}
                    className="mySwiper"
                >
                    {
                        data?.results?.map((item, index) => {
                            return (
                                <Col xl={4} lg={6} md={8} sm={8} xs={12} key={index}>
                                    <SwiperSlide>
                                        <div
                                            className='custom-card p-4 h-[100%] cursor-pointer'
                                            onClick={() => {
                                                navigate(`/local-prices/details/${item?.products_codes}?${queryString.stringify({ title: item?.name })}`)
                                            }}
                                        >
                                            <div className='text-center' style={{ height: '100px' }}>
                                                <img src={item?.picture} alt={item?.name} className='w-[100px] mb-4' />
                                            </div>
                                            <p className='text-center font-bold'>{item?.name}</p>
                                            <div className='text-left'>
                                                {item?.last_price && <p>{t("price")}  <b>{reFormat(item?.last_price)} {t('sum')}</b></p>}
                                                {item?.last_date && <p>{t("date")} <b>{setUTCTime(item?.last_date, "YYYY-MM-DD")}</b></p>}
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                </Col>
                            )
                        })
                    }
                </Swiper>
                {
                    data?.results?.length === 0 ? <Empty /> : null
                }
            </Row>
            <br />
            <Divider />
        </div>
    )
}

export default LocalProductPrices