import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Col, Empty, Input, Pagination, Row } from 'antd'
import Loader from '../loader'
import Error from '../error'
import queryString from 'query-string'
import { useTranslation } from 'react-i18next'
import http from '../../utils/helpers'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

const ForeignImporters = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [search, setSearch] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(24)

    const filter = useMemo(() => {
        return {
            search,
            page: search.length > 0 ? 1 : currentPage,
            page_size: pageSize,
        }
    }, [search, currentPage, pageSize])

    const { data, isLoading, isError } = useQuery({
        queryKey: ['importers-country', filter],
        queryFn: async () => {
            return await http.get(`export/importers/country/`, {
                params: filter,
            })
        },
        placeholderData: keepPreviousData
    })

    return (
        <div className='p-5' style={{ minHeight: 'calc(100vh - 250px)' }}>
            <div className='mb-5 flex align-center'>
                <div className='arrow-icon' onClick={() => navigate('/')}></div>
                <h2 className='m-0'>{t('foreign_importers')}</h2>
            </div>

            <div className='text-right mb-5 flex justify-between items-center'>
                <span>{t('total')} {data?.count} {t('ta')}</span>
                <Input
                    placeholder={t('search')}
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    className='w-[250px] text-left'
                />
            </div>

            {
                isLoading ? <Loader /> : (
                    <>
                        <Row gutter={[24, 24]}>
                            {
                                data?.results?.map((item, index) => {
                                    return (
                                        <Col xl={4} lg={6} md={8} sm={12} xs={12} key={index}>
                                            <div
                                                className='custom-card p-4 py-8 min-h-36 justify-center flex items-center flex-col cursor-pointer'
                                                onClick={() => {
                                                    navigate(`inner-foreign-importers/${item?.id}?${queryString.stringify({ title: item?.name })}`)
                                                }}>
                                                <img src={item?.flag} alt={item?.name} className='xl:w-16 w-12 mb-2' />
                                                <h4 className='m-0 text-black text-center mt-auto'>{item?.name}</h4>
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>

                        {
                            data?.results?.length === 0 ? <Empty /> : null
                        }

                        {
                            Array.isArray(data?.results) ? (
                                <div className='text-right mt-5'>
                                    <Pagination
                                        defaultCurrent={currentPage}
                                        current={currentPage}
                                        pageSize={pageSize}
                                        showSizeChanger={true}
                                        pageSizeOptions={[20, 50, 100]}
                                        onChange={(current, size) => {
                                            setCurrentPage(current)
                                            setPageSize(size)
                                        }}
                                        total={data?.count}
                                    />
                                </div>
                            ) : null
                        }
                    </>
                )
            }
            {isError ? <Error /> : null}
        </div>
    )
}

export default ForeignImporters