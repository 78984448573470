import { useMutation, useQuery } from '@tanstack/react-query'
import { Col, Empty, Input, Row } from 'antd'
import queryString from 'query-string'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { narxUserLogin } from '../../reducers/authSlice'
import narxHttp from '../../utils/helpers/narx-http'
import Error from '../error'
import Loader from '../loader'

const AbroadPrices = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const { narxAccessToken } = useSelector(state => state.auth)

    const [search, setSearch] = useState("")

    const filter = useMemo(() => {
        return {
            search
        }
    }, [search])

    const { mutate, data: authData } = useMutation({
        mutationKey: ['price-auth'],
        mutationFn: async () => {
            return await narxHttp.post(`/v1/account/login/`, {
                password: "1",
                email: "admin@gmail.com"
            })
        },
        onSuccess: (data) => {
            dispatch(narxUserLogin(data))
        }
    })

    useEffect(() => {
        if (!narxAccessToken) {
            mutate()
        }
    }, [mutate, narxAccessToken])

    const { data, isLoading, isError } = useQuery({
        queryKey: ['price-common-countries', filter],
        queryFn: async () => {
            return await narxHttp.get(`/v1/common/countries/?for_web=true`, {
                params: filter,
            })
        },
        enabled: !!authData || !!narxAccessToken,
    })

    return (
        <div className='p-5' style={{ minHeight: 'calc(100vh - 250px)' }}>
            <div className='mb-5 flex align-center'>
                <div className='arrow-icon' onClick={() => navigate(-1)}></div>
                <h2 className='m-0'>{t('abroad_prices')}</h2>
            </div>

            <div className='text-right mb-5 flex justify-between items-center'>
                <span>{t('total')} {data?.length} {t('ta')}</span>
                <Input
                    placeholder={t('search')}
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    className='w-[250px] text-left'
                />
            </div>

            {
                isLoading ? <Loader /> : (
                    <>
                        <Row gutter={[24, 24]}>
                            {
                                data?.map((item, index) => {
                                    return (
                                        <Col xl={4} lg={6} md={8} sm={12} xs={12} key={index}>
                                            <div
                                                className='custom-card p-4 py-8 min-h-36 justify-center flex items-center flex-col cursor-pointer'
                                                onClick={() => {
                                                    navigate(`inner-abroad-price/${item?.id}?${queryString.stringify({ title: item?.name })}`)
                                                }}>
                                                <img src={item?.flag} alt={item?.name} className='xl:w-16 w-12 mb-2' />
                                                <h4 className='m-0 text-black text-center mt-auto'>{item?.name}</h4>
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>

                        {
                            data?.length === 0 ? <Empty /> : null
                        }
                    </>
                )
            }
            {isError ? <Error /> : null}
        </div>
    )
}

export default AbroadPrices