import React, { useState } from 'react'
import './footer.css'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import headerLogo from '../../assets/images/header-logo.png'
// import { AiOutlineMessage } from "react-icons/ai"
import { RiTelegramLine } from "react-icons/ri"
import http from '../../utils/helpers'
import { useQuery } from '@tanstack/react-query'
import { Col, Row } from 'antd'
import VisitorsStatistics from '../visitors'
import SupplyAndDemandModal from '../../modals/supply-demand-modal'
import appStoreImg from '../../assets/images/app-store.png'
import androidImg from '../../assets/images/android.png'

const Footer = () => {
    const { t } = useTranslation()

    const [isOpenModal, setIsOpenModal] = useState(false)

    const { data } = useQuery({
        queryKey: ['common-links'],
        queryFn: async () => {
            return await http.get(`common/links/`)
        },
    })

    return (
        <>
            <div className='footer py-5'>
                <div className='container inner-footer'>
                    <div className='column'>
                        <Link to='/'>
                            <div className='footer-logo'>
                                <img src={headerLogo} alt='footer-logo' className='logo' />
                                <h5>{t('header')}</h5>
                            </div>
                        </Link>
                    </div>

                    <div className='column2'>
                        <h5>{t('useful-tgm')}</h5>
                        <Row>
                            {
                                data?.results?.map((item, index) => {
                                    return (
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24} key={index}>
                                            <a href={item?.link} target='_blank' key={index} rel="noreferrer">
                                                <div className='top'>
                                                    <RiTelegramLine className='footer-icon' /><p>{item?.title}</p>
                                                </div>
                                            </a>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </div>

                    <div className='column'>
                        <p className='m-0'>
                            <b className='mr-2'>{t('call_center')}</b>
                            <br />
                            <a href='tel:1288' className='text-[white] text-[18px] mr-2'>1288,</a>
                            <a href='tel:(+99871) 202 10 00' className='text-[white] text-[18px]'>(+99871) 202 10 00</a>
                        </p>
                        {/* <div className='footer-row' style={{ cursor: "pointer" }} onClick={() => setIsOpenModal(true)}>
                            <AiOutlineMessage className='footer-icon' />
                            <h6>{t('supply')}</h6>
                        </div> */}
                        <VisitorsStatistics />
                        <div className='flex mt-4'>
                            <a href='https://apps.apple.com/uz/app/agrokomakchi/id1658946098' target='_blank' rel="noreferrer">
                                <div className='mr-4'>
                                    <img src={appStoreImg} alt='' className='w-[40px] h-[40px]' />
                                </div>
                            </a>
                            <a href='https://play.google.com/store/apps/details?id=uz.karantin.agrokomakchi' target='_blank' rel="noreferrer">
                                <div className=''>
                                    <img src={androidImg} alt='' className='w-[40px] h-[40px]' />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {
                isOpenModal ? (
                    <SupplyAndDemandModal
                        setIsOpenModal={setIsOpenModal}
                        isOpenModal={isOpenModal}
                    />
                ) : null
            }
        </>
    )
}

export default Footer