import { Divider, Row } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as BegonaUtSvg } from "../../assets/icons/begona-ut.svg"
import { ReactComponent as KasallikSvg } from "../../assets/icons/kasallik.svg"
import { ReactComponent as UygunlashganKurashSvg } from "../../assets/icons/uygunlashgan-kurash.svg"
import { ReactComponent as ZararkunandaSvg } from "../../assets/icons/zararkunanda.svg"
import Card from '../../components/card'

const Protection = () => {
    const { t } = useTranslation()

    const usimlikHimoyasi = [
        {
            id: "ZARARKUNANDA",
            title: t('pests'),
            icon: ZararkunandaSvg,
            color: 'red',
            link: 'products'
        },
        {
            id: "KASALLIK",
            title: t('diseases'),
            icon: KasallikSvg,
            color: 'red',
            link: 'products'
        },
        {
            id: "BEGONA_UTLAR",
            title: t('weeds'),
            icon: BegonaUtSvg,
            color: 'red',
            link: 'products'
        },
        {
            id: null,
            title: t('harmonized_protection'),
            icon: UygunlashganKurashSvg,
            color: 'red',
            link: 'ipm',
        },
    ]
    return (
        <>
            <h1 className='mb-5'>{t('protection')}</h1>
            <Row
                gutter={[24, 24]}
            >
                {
                    usimlikHimoyasi?.map((item, index) => {
                        return <Card key={index} item={item} />
                    })
                }
            </Row>
            <br />
            <Divider />
        </>
    )
}

export default Protection