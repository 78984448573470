import { Divider, Row } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as HududSvg } from "../../assets/icons/hudud.svg"
import Card from '../../components/card'

const Import = () => {
    const { t } = useTranslation()

    const imports = [
        {
            id: "green",
            title: t('phytosanitary_clean_areas'),
            icon: HududSvg,
            color: 'green',
            link: 'zone-countries'
        },
        {
            id: "red",
            title: t('temporarily_banned_businesses'),
            icon: HududSvg,
            color: 'red',
            link: 'zone-countries'
        },
    ]

    return (
        <>
            <h1 className='mb-5'>{t('import')}</h1>
            <Row
                gutter={[24, 24]}
            >
                {
                    imports?.map((item, index) => {
                        return <Card key={index} item={item} />
                    })
                }
            </Row>
            <br />
            <Divider />
        </>
    )
}

export default Import