import { Divider, Row } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ElektronKutubxonaSvg } from "../../assets/icons/elektron-kutubxona.svg"
import { ReactComponent as ExportImportInspectorSvg } from "../../assets/icons/export-import-inspektor.svg"
import { ReactComponent as FoydaliVideolarSvg } from "../../assets/icons/foydali-videolar.svg"
import { ReactComponent as KuchatlarSvg } from "../../assets/icons/kuchatlar.svg"
import { ReactComponent as MineralUgitlarSvg } from "../../assets/icons/mineral-ugitlar.svg"
import { ReactComponent as PetsidsidmineralSvg } from "../../assets/icons/petsidsid-mineral.svg"
import { ReactComponent as TaqiqlanganModdalarSvg } from "../../assets/icons/taqiqlangan-moddalar.svg"
import { ReactComponent as TuproqTahlilSvg } from "../../assets/icons/tuproq-tahlil.svg"
import { ReactComponent as TuproqUnumdorligiSvg } from "../../assets/icons/tuproq-unumdorligi.svg"
import { ReactComponent as YagonaDarchaSvg } from "../../assets/icons/yagona-darcha.svg"
import { ReactComponent as UruglarSvg } from "../../assets/icons/uruglar.svg"
import Card from '../../components/card'
import http from '../../utils/helpers'

const UsefulInformation = () => {
    const { t } = useTranslation()

    const foydaliMalumotlar = [
        {
            id: null,
            title: t('authorized_protective_equipment'),
            icon: PetsidsidmineralSvg,
            color: 'brown',
            link: 'minerals'
        },
        {
            id: null,
            title: t('export_inspector'),
            icon: ExportImportInspectorSvg,
            color: 'brown',
            link: 'inspektor'
        },
        {
            id: null,
            title: t('seeds'),
            icon: UruglarSvg,
            color: 'brown',
            link: 'seeds'
        },
        {
            id: null,
            title: t('seedlings'),
            icon: KuchatlarSvg,
            color: 'brown',
            link: 'kochat'
        },
        {
            id: null,
            title: t('criteria_type'),
            icon: TaqiqlanganModdalarSvg,
            color: 'brown',
            link: "taqiqlangan-cheklangan-faol-moddalar"
        },
        {
            id: null,
            title: t('electronic_library'),
            icon: ElektronKutubxonaSvg,
            color: 'brown',
            link: 'library',
        },
        {
            id: "soil",
            title: t('soil_analysis'),
            icon: TuproqTahlilSvg,
            color: 'brown',
            type: 'download'
        },
        {
            id: "productivity",
            title: t('increase_soil_fertility'),
            icon: TuproqUnumdorligiSvg,
            color: 'brown',
            type: 'download'
        },
        {
            id: "mineral",
            title: t('mineral_fertilizers'),
            icon: MineralUgitlarSvg,
            color: 'brown',
            type: 'download'
        },
        {
            id: null,
            title: t('useful_videos'),
            icon: FoydaliVideolarSvg,
            color: 'brown',
            link: 'useful-videos'
        },
        {
            id: null,
            title: t('single_window_system'),
            icon: YagonaDarchaSvg,
            color: 'brown',
            link: 'single-window-videos'
        },
    ]

    const getData = async (id) => {
        try {
            const response = await http.get('/agro/analy/file', {
                params: {
                    type: id
                }
            })
            const href = response?.data[0] ? response?.data[0].file : null
            const link = document.createElement('a')
            link.target = "_blank"
            link.href = href
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <>
            <h1 className='mb-5'>{t('useful_information')}</h1>
            <Row
                gutter={[24, 24]}
            >
                {
                    foydaliMalumotlar?.map((item, index) => {
                        return <Card key={index} item={item} getData={getData} />
                    })
                }
            </Row>
            <br />
            <Divider />
        </>
    )
}

export default UsefulInformation