import { useQuery } from '@tanstack/react-query';
import { Button, Spin } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import Chart from "react-apexcharts";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { reFormat } from '../../utils';
import http from '../../utils/helpers';
import { useTranslation } from 'react-i18next';

const TimeseriesChart = (props) => {
    const { t } = useTranslation()
    const { dateStrings, regionId, defaultMonth, setDefaultMonth, productCodes, districtId } = props
    console.log(productCodes)

    const [initPrice, setInitPrice] = useState(0)
    const [lastPrice, setLastPrice] = useState(0)

    const { data, isLoading } = useQuery({
        queryKey: ['export-local-price-timeseries', dateStrings, regionId, productCodes, districtId],
        queryFn: async () => {
            return await http.get(`export/local/price/timeseries/`, {
                params: {
                    from_date: dateStrings[0],
                    to_date: dateStrings[1],
                    region_id: regionId,
                    district_id: districtId,
                    products_codes: productCodes,
                }
            })
        },
        enabled: !!regionId && !!districtId
    })

    useEffect(() => {
        if (data && data?.data?.prices?.length > 0) {
            setInitPrice(data?.data?.prices[0])
            setLastPrice(data?.data?.prices[data?.data?.prices.length - 1])
        }
    }, [data])

    if (isLoading) {
        return <div style={{ width: "100%", height: "500px", justifyContent: "center", alignItems: "center", display: "flex" }}>
            <Spin />
        </div>
    }
    if (data?.data?.prices) {
        let diff = (lastPrice - initPrice).toFixed(2)

        return (
            <div style={{ marginBottom: "20px" }}>
                {
                    lastPrice && initPrice && (
                        <div style={{ border: "1px solid #00000020", width: "250px", padding: "10px", borderRadius: "4px", backgroundColor: "white", marginBottom: "10px", marginLeft: "auto" }}>
                            <h1 style={{ fontSize: "18px", fontWeight: "bold", margin: "0" }}>{reFormat(lastPrice)} {t("sum")}</h1>
                            <div style={{ display: "flex", gap: "5px", fontSize: "14px" }}>
                                <span style={{ color: diff >= 0 ? "green" : "red" }}>{diff >= 0 ? <FaArrowUp size={"12px"} /> : <FaArrowDown size={"12px"} />} {((lastPrice * 100) / initPrice - 100).toFixed(2)}%</span>
                                <span>{diff} {t("sum")}</span>
                                <span style={{ color: "grey" }}>{defaultMonth} {t("in-month")}</span>
                            </div>
                        </div>
                    )
                }
                <Chart
                    options={{
                        chart: {
                            zoom: {
                                type: 'x',
                                enabled: true,
                                autoScaleYaxis: true,
                            },
                            toolbar: {
                                autoSelected: 'zoom'
                            }
                        },
                        dataLabels: {
                            enabled: false
                        },
                        stroke: {
                            curve: 'straight',
                            width: 2
                        },
                        fill: {
                            type: 'gradient',
                            gradient: {
                                shadeIntensity: 1,
                                inverseColors: false,
                                opacityFrom: 0.5,
                                opacityTo: 0,
                                stops: [0, 90, 100]
                            },
                        },
                        grid: {
                            row: {
                                colors: ['#f3f3f3', 'transparent'],
                                opacity: 0.5
                            },
                        },
                        xaxis: {
                            // categories: data?.data?.dates,asdf
                            categories: data?.data?.dates?.map((item, index) => {
                                return dayjs(item).format('YYYY-MM-DD')
                            }),
                            labels: {
                                formatter: function (val) {
                                    return val
                                },
                                show: false,
                            },
                            // max: 100,
                            // min: 20
                            // stepSize: 100
                            // range: 20
                            // tickAmount: 10
                        },
                        yaxis: {
                            labels: {
                                formatter: function (val) {
                                    return reFormat(val);
                                },
                            },
                            // min: 9,f
                            // max: 25
                        },
                        tooltip: {
                            y: {
                                formatter: function (val) {
                                    return reFormat(val) + t("sum");
                                }
                            }
                        }
                    }}
                    series={[
                        {
                            name: t("price"),
                            data: data?.data?.prices
                        }
                    ]}
                    type="area"
                    width="100%"
                    height={"500px"}
                />
                <div style={{ display: "flex", gap: "5px", justifyContent: "center", overflow: "auto" }}>
                    <Button onClick={() => setDefaultMonth(6)} type={defaultMonth === 6 && 'primary'}>6 {t("monthly")}</Button>
                    <Button onClick={() => setDefaultMonth(12)} type={defaultMonth === 12 && 'primary'}>1 {t("yearly")}</Button>
                    <Button onClick={() => setDefaultMonth(36)} type={defaultMonth === 36 && 'primary'}>3 {t("yearly")}</Button>
                </div>
            </div>
        )
    }
}

export default TimeseriesChart