import { Divider, Row } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as YigirmaKundaHosilSvg } from "../../assets/icons/20-kunda-hosil.svg"
import { ReactComponent as AgroUzexSvg } from "../../assets/icons/agrouzex.svg"
import { ReactComponent as DavlatlarSvg } from "../../assets/icons/davlatlar.svg"
import { ReactComponent as ExportMaydoniSvg } from "../../assets/icons/export-maydoni.svg"
import { ReactComponent as ExportchiKorxonalarSvg } from "../../assets/icons/exportchi-korxonalar.svg"
import { ReactComponent as LogistikaSvg } from "../../assets/icons/logistika.svg"
import { ReactComponent as MahsulotlarSvg } from "../../assets/icons/mahsulotlar.svg"
import { ReactComponent as NarxlarSvg } from "../../assets/icons/narxlar.svg"
import { ReactComponent as OrganikKorxonalarSvg } from "../../assets/icons/organik-korxonalar.svg"
import { ReactComponent as QadoqlashKorxonalarSvg } from "../../assets/icons/qadoqlash-korxonalari.svg"
import { ReactComponent as QadoqlashMahsulotlarSvg } from "../../assets/icons/qadoqlash-mahsulotlar.svg"
import { ReactComponent as TayyorMahsulotlarSvg } from "../../assets/icons/tayyor-mahsulotlar.svg"
import { ReactComponent as UlgurjiSavdoMarkazlariSvg } from "../../assets/icons/ulgurji-savdo-markazlari.svg"
import { ReactComponent as XorijdagiSavdoAgentlariSvg } from "../../assets/icons/xorijdagi-savdo-agentlari.svg"
import { ReactComponent as XorijiyBrendKorxonalarSvg } from "../../assets/icons/xorijiy-brend-korxonalar.svg"
import { ReactComponent as XoijiyImportchilarSvg } from "../../assets/icons/xorijiy-importchilar.svg"
import { ReactComponent as XorijiyTexnologlarSvg } from "../../assets/icons/xorijiy-texnologlar.svg"
import Card from '../../components/card'

const Eksport = () => {
    const { t } = useTranslation()

    const eksport = [
        {
            id: null,
            title: t('countries'),
            icon: DavlatlarSvg,
            color: 'blue',
            link: 'export-countries'
        },
        {
            id: null,
            title: t('products'),
            icon: MahsulotlarSvg,
            color: 'blue',
            link: 'mahsulotlar'
        },
        {
            id: null,
            title: t('texnolog'),
            icon: XorijiyTexnologlarSvg,
            color: 'blue',
            link: 'foreign-technologs'
        },
        {
            id: null,
            title: t('brand_company'),
            icon: XorijiyBrendKorxonalarSvg,
            color: 'blue',
            link: 'foreign-brands'
        },
        {
            id: null,
            title: t('successful_company'),
            icon: UlgurjiSavdoMarkazlariSvg,
            color: 'blue',
            link: 'shopping-centers'
        },
        {
            id: null,
            title: t('b2b'),
            icon: ExportMaydoniSvg,
            color: 'blue',
            url: 'https://akk.karantin.uz/api/v2/common/url/asia_export/'
        },
        {
            id: null,
            title: "AgroUzex",
            icon: AgroUzexSvg,
            color: 'blue',
            url: 'https://agro.uzex.uz/home'
        },
        {
            id: null,
            title: t('organic_companies'),
            icon: OrganikKorxonalarSvg,
            color: 'blue',
            link: 'organic-companies',
        },
        {
            id: null,
            title: t('fruits'),
            icon: NarxlarSvg,
            color: 'blue',
            link: 'prices',
        },
        {
            id: null,
            title: t('packaging_companies'),
            icon: QadoqlashKorxonalarSvg,
            color: 'blue',
            link: 'qadoqlash-korxonalar'
        },
        {
            id: null,
            title: t('packaging_materials'),
            icon: QadoqlashMahsulotlarSvg,
            color: 'blue',
            link: 'qadoqlash-mahsulotlar'
        },
        {
            id: null,
            title: t('exporter_company'),
            icon: ExportchiKorxonalarSvg,
            color: 'blue',
            link: 'export-companies'
        },
        {
            id: null,
            title: t('logistics'),
            icon: LogistikaSvg,
            color: 'blue',
            link: 'logistic'
        },
        {
            id: null,
            title: t('sales_agents'),
            icon: XorijdagiSavdoAgentlariSvg,
            color: 'blue',
            link: 'sales-agents'
        },
        {
            id: null,
            title: t('crops_area'),
            icon: TayyorMahsulotlarSvg,
            color: 'blue',
            url: 'https://karantin.uz/uploads/menu/yetishtirilgan_ekin_maydonlari.xlsx'
        },
        {
            id: null,
            title: t('expected_harvest'),
            icon: YigirmaKundaHosilSvg,
            color: 'blue',
            url: 'https://karantin.uz/uploads/menu/crop.xlsx'
        },
        {
            id: null,
            title: t('foreign_importers'),
            icon: XoijiyImportchilarSvg,
            color: 'blue',
            link: 'foreign-importers'
        },
    ]

    return (
        <>
            <h1 className='mb-5'>{t('export')}</h1>
            <Row
                gutter={[24, 24]}
            >
                {
                    eksport?.map((item, index) => {
                        return <Card key={index} item={item} url={item?.url} />
                    })
                }
            </Row>
            <br />
            <Divider />
        </>
    )
}

export default Eksport