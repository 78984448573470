import React, { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Col, Empty, Input, Row } from 'antd'
import Loader from '../loader'
import Error from '../error'
import queryString from 'query-string'
import { useTranslation } from 'react-i18next'
import { setUTCTime } from '../../utils'
import { useQuery } from '@tanstack/react-query'
import narxHttp from '../../utils/helpers/narx-http'

const InnerAbroadPrice = () => {
    const navigate = useNavigate()
    const params = useParams()
    const { t } = useTranslation()
    const { title } = queryString.parse(window.location.search)

    const [search, setSearch] = useState("")

    const filter = useMemo(() => {
        return {
            where_come_from: params.countryId,
            search,
        }
    }, [search, params.countryId])

    const { data, isLoading, isError } = useQuery({
        queryKey: ['export-app-products-where', filter],
        queryFn: async () => {
            return await narxHttp.get(`/v2/export/app/products/`, {
                params: filter,
            })
        }
    })

    return (
        <div className='p-5' style={{ minHeight: 'calc(100vh - 250px)' }}>
            <div className='mb-5 flex align-center'>
                <div className='arrow-icon' onClick={() => navigate(-1)}></div>
                <h2 className='m-0'>{title}</h2>
            </div>

            <div className='text-right mb-5 flex justify-between items-center'>
                <span>{t('total')} {data?.length} {t('ta')}</span>
                <Input
                    placeholder={t('search')}
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    className='w-[250px] text-left'
                />
            </div>

            {
                isLoading ? <Loader /> : (
                    <>
                        <Row gutter={[24, 24]}>
                            {
                                data?.map((item, index) => {
                                    return (
                                        <Col xl={6} lg={8} md={12} sm={24} xs={24} key={index}>
                                            <div
                                                className='custom-card p-4 h-[100%] cursor-pointer'
                                                onClick={() => {
                                                    navigate(`details/${item?.id}?${queryString.stringify({ title: `${title} / ${item?.product_name}` })}`)
                                                }}>
                                                <div className='text-center'>
                                                    <img src={item?.image} alt={item?.product_name} className='w-[100px] mb-4' />
                                                </div>
                                                <h3 className='text-[#147461] mb-3'>{item?.product_name}</h3>
                                                <div className='text-left'>
                                                    {item?.market_max_price && <p>{t('max')} <b>{item?.market_max_price} {item?.market_currency_type}</b></p>}
                                                    {item?.market_min_price && <p>{t('min')} <b>{item?.market_min_price} {item?.market_currency_type}</b></p>}
                                                    {item?.market_avg_price && <p>{t('avg')} <b>{item?.market_avg_price} {item?.market_currency_type}</b></p>}
                                                    <p>{t('update_date')} <b>{setUTCTime(item?.last_price_updated_date, 'YYYY-MM-DD')}</b></p>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>

                        {
                            data?.length === 0 ? <Empty /> : null
                        }
                    </>
                )
            }
            {isError ? <Error /> : null}
        </div>
    )
}

export default InnerAbroadPrice